import React, {FC, useState, useCallback, useEffect} from "react";
import {useParams, useHistory} from "react-router";
import {ROUTE as ROUTES, ROUTE} from "../../services/RouteService/enums";
import {openShop, PATHS, urlTo} from "../../services/RouteService/RouteService";
import Button from "../UI/Button/Button";
import Link from "../UI/Link";

import "./VerifyEmail.styl";
import {VerifyEmailProps} from "./types";
import {FormattedHTMLMessage} from "../../services/TextService";
import cn from "classnames";

import client from "../../services/apollo";
import Mutation from "../../graphql/Mutation";
import {GA} from "../../services/Analitics";
import {AUTH_NEW_ENTRANCE_ACTIONS} from "../../constants";

const VerifyEmail: FC<VerifyEmailProps> = (props) => {
	const {verifyId} = useParams<any>();
	const history = useHistory();
	const [selectedPath, setSelectedPath] = useState('');
	const [error, setError] = useState('');
	const [success, setSuccess] = useState(false)


	useEffect(() => {
		const verifyEmail = () => {
			client.mutate({
				...Mutation.verifyEmail,
				variables: {
					hash: verifyId
				}
			})
				.then(async ({data = {}, errors}) => {
					if (data.verifyEmail === true) {
						setSuccess(true);
					} else {
						setError("Перепрошуємо, нам не вдалося підтвердити email.<br />" +
							"Не хвилюйтеся, це не вплине на ваш досвід використання сервісів \"Фора\" - ми завжди раді вас бачити  :)");
					}
				}).catch((err) => {
					console.log('ERROR',err)
				if (err.graphQLErrors && Array.isArray(err.graphQLErrors) && err.graphQLErrors.length > 0) {
					setError(err.graphQLErrors[0].message);
				}
				GA("event", "auth", {
					"event_category": "entrance-error",
					"event_label": AUTH_NEW_ENTRANCE_ACTIONS.authEntrance
				});
			})
		}
		verifyEmail();
	}, [verifyId]);

	const goTo = useCallback(() => {
		if (selectedPath === "personal-page") {
			history.push(urlTo(ROUTES.PERSONAL_PAGE));
		}
		if (selectedPath === "shop") {
			openShop();
		}
	}, [selectedPath, history]);

	return (
		<div className="verify-email-wrapper">
			<i className="verify-email__bg"/>
			<div className="verify-email__body">
				<Link to={PATHS[ROUTE.ROOT]} className="logo-row">
					<img className="verify-email__logo" src="/images/logo.svg" alt="Логотип"/>
				</Link>
				<div>
					{success &&
					<div className="verify-email__text">
						<FormattedHTMLMessage id="email-verification.text"/>
					</div>
					}
					{error &&
					<div className="verify-email__error" dangerouslySetInnerHTML={{ __html: error }}/>
					}
					<div className="verify-email__hint">
						<FormattedHTMLMessage id="email-verification.go.to"/>
					</div>
					<div className="verify-email__btn-wrapper">
						<div className={cn("verify-email__btn btn_to-personal-page", {active: selectedPath === "personal-page"})}
								 onClick={() => setSelectedPath("personal-page")}>
							<div className="btn-react">
								<img className="verify-email__btn-img" src="/images/fora/user.svg" alt="Користувач"/>
								<FormattedHTMLMessage id="email-verification.to.personal.page"/>
							</div>
						</div>
						<div className={cn("verify-email__btn btn_to-shop", {active: selectedPath === "shop"})}
								 onClick={() => setSelectedPath("shop")}>
							<div className="btn-react">
								<img className="verify-email__btn-img" src="/images/fora/shop.svg" alt="Магазин"/>
								<FormattedHTMLMessage id="email-verification.to.purchase"/>
							</div>
						</div>
					</div>
					<div className="verify-email__footer">
						<Button
							className="btn-primary"
							onClick={goTo}
							disabled={!selectedPath}
						>
							<FormattedHTMLMessage id="email-verification.btn"/>
						</Button>
					</div>
				</div>
			</div>
		</div>
	)

}

export default VerifyEmail;

